<template>
  <div class="app-main-scroll-wrap content">
    <el-steps :active="active" finish-status="success" simple style="margin: 0 -12px 0 -12px; ">
      <el-step class="tip five" title="1、下载导单模版并填写"></el-step>
      <el-step class="tip five" title="2、导入填写好的模版文件" :class="[!active ? 'step1' : '']"></el-step>
      <el-step class="tip five" title="3、确认订单并支付"></el-step>
    </el-steps>
    <div v-if="!active" style="margin-top: 20px;padding: 0 20px;">
      <div>本页面用于批量导入订单，请先点击 <a href="javascript:;" @click="downTemplate">下载导单模版</a> ，填写模版时注意事项:</div>
      <div style="margin: 30px 0; ">
        <div>1、单次导入订单商品总行数不要超过1000条</div>
        <div>2、模版中每行填写一个商品，当一个收货人地址购买多个商品时，则填写多行，系统会根据收货人姓名、手机号和详细地址判断是否为同一收货人，并合并为一个订单</div>
        <div>3、商品编号、数量为必填，数量必须为大于0的正整数</div>
        <div>4、收货人姓名必填，不要超过50个字</div>
        <div>5、手机号必填，请按正确格式和11位数字填写</div>
        <div>
          6、详细地址必慎，需要包括省市区和具体地址，整体不要超过100个字，详细地址省市区请仔细核查，例如有没有写区/县，或者越过地级市直接写县级市，或者写高新区之类民政部没有的区，如果对地址库有疑问，请访问 <a
            href="http://xzqh.mca.gov.cn/map" arget="_blank">http://xzqh.mca.gov.cn/map</a> 查询
        </div>
        <div>7、订单备注为选填，不要超过200个字，当多行商品为同一收货人地址，系统合并为一个订单后，订单备注取第一条商品行的订单备注</div>
      </div>
      <upload-excel-component size="small" type="primary" text="导入订单" defaultSize :on-success="excelSuccess" />
    </div>
    <submitOrder v-else :batch_sn="batch_sn"></submitOrder>
    <!-- 导入失败提示 -->
    <el-dialog-x-dialog :close-on-click-modal="true" :append-to-body="true" title="导入失败" :visible.sync="isVisible"
      width="320px">
      <div style="display: flex; width: 300px;height: 150px;align-items: center;">
        <div style="width: 50px;">
          <i class="el-icon-error" style="font-size: 50px; color: red;"></i>
        </div>
        <div style="flex: 1;">很抱歉，因导入信息有误，收货人导入失败，请点击下载
          <a href="javascript:;" @click="downDataTemplate">失败原因文件</a>
          ，修改或删除后重新导入
        </div>
      </div>
    </el-dialog-x-dialog>
    <!-- 提交订单错误提示 -->
    <x-dialog :proxy="checkCreateErrstatusDialog">
      <div>
        <p>{{ batch_sn ? `部分订单已提交成功！` : `订单提交失败！` }}</p>
        <p>{{ `${batch_sn ? '但因' : ''}如下商品库存不足，${batch_sn ? '部分' : '导致全部'}订单提交失败，请下载生单失败文件调整后补充下单！` }}</p>
        <div style="max-height: 200px;overflow-y: auto;min-height: 100px;">
          <div v-for="item in partialSuccessData.err_order" :key="item.goods_id">{{ item.goods_alias || item.name
            || ''
            }}
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 10px;">
          <div>
            <el-button size="mini" @click="downDataTemplate">下载生单失败文件</el-button>
            <el-button size="mini" v-if="batch_sn" type="primary" @click="confirmPay">继续确认支付</el-button>
          </div>
        </div>
      </div>
    </x-dialog>
    <Loading v-if="showLoading" />
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/UploadExcel";
import submitOrder from '../../shopGoods/components/submitOrder.vue'
import { handleDownload, downloadErr } from "@/utils";
import { importOrder } from '@/api/importOrder'
import Loading from './components/loading.vue'
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import XDialog from "@/components/x-dialog/x-dialog.vue";
// 获取年月日时分秒
const getDate = () => { // 获取当前时间
  const addZeroForNum = (num) => {
    return num < 10 ? '0' + num : num
  }
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours(); // 获取当前小时数(0-23)
  const minute = date.getMinutes(); // 获取当前分钟数(0-59)
  const second = date.getSeconds(); // 获取当前秒数(0-59)

  const time = `${year}${addZeroForNum(month)}${addZeroForNum(day)}${addZeroForNum(hour)}${addZeroForNum(minute)}${addZeroForNum(second)}`;
  return time;
}

/**获取生单错误数据 */
const getDownList = (data) => {
  if (!data || !Array.isArray(data) || !data.length) return []
  const $ = (t) => t ?? ''
  return data.map((item) => {
    const sku = item?.order_list?.[0]?.sku_list?.[0] ?? {}
    const consignee = item?.order_list?.[0]?.consignee ?? {}
    const goods_sn = sku.sku_sn
    const num = sku.num
    const addr_name = consignee.name
    const mobile = consignee.mobile
    const addr = $(consignee.province) + $(consignee.city) + $(consignee.county) + $(consignee.town) + $(consignee.address)
    const order_remark = item.remark
    const error_msg = item.err_msg
    return { goods_sn, num, addr_name, mobile, addr, order_remark, error_msg }
  })
}
export default {
  name: 'ManagerOneClickDeliveryImportOrder',
  components: { UploadExcelComponent, submitOrder, XDialog, Loading },
  data () {
    return {
      active: 0,
      batch_sn: '',
      isVisible: false,
      downList: [],
      showLoading: false,
      checkCreateErrstatusDialog: $xDialog.create({
        title: "提示",
        width: "480px",
        disableConfirm: true,
        disableCancel: true,
        afterDismiss: () => {
          this.checkCreateErrstatusDialog.dismiss()
          if (!this.result?.data?.batch_no || this.result?.data?.normal_order?.length === 0) return undefined
          this.active = 2
        },
        beforeConfirm: () => {
          this.checkCreateErrstatusDialog.dismiss()
        }
      }),
      partialSuccessData: {},
      result: {}, // 导入结果
      isShowConfirm: true, // 是否显示确认支付
    };
  },

  mounted () {
    if (this.$route.query.batch_sn) {
      this.batch_sn = this.$route.query.batch_sn
      this.active = 2
    }
  },
  methods: {
    downTemplate () {
      // 下载模板
      let tHeaders = [], filterVals = [];
      tHeaders = ["商品编号", "数量", '收货人姓名', '手机号', '详细地址', '订单备注'];
      handleDownload(
        [],
        tHeaders,
        filterVals,
        "导单模版"
      );
    },
    async excelSuccess ({ results }) {
      try {
        const tHeaders = ["商品编号", "数量", '收货人姓名', '手机号', '详细地址', '订单备注']
        if (!Array.isArray(results) || !results.length) return this.$message.error('请勿导入空模板数据')
        const tmpHName = Object.entries(results[0]).map(([k]) => k)
        const emptyData = tHeaders.slice(0, tHeaders.length - 1).filter(item => !tmpHName.includes(item))
        // if (emptyData.length) return this.$message.error(`${emptyData.join(',')} 列数据为空，请检查模板数据是否正确`)
        if (emptyData.length) return this.$message.error(`存在必填项未填写，请进行填写`)
        const isRight = tmpHName.every((item, index) => item === tHeaders[index])
        if (!isRight) return this.$message.error('模板不正确，请下载模板重新上传')
        if (results.length > 1000) return this.$message.error('导入失败，一次最多可导入收货人行数1000条，请进行删减后重试')
        this.showLoading = true
        const resultsTmp = this.handleResults(results)
        const res = await importOrder(resultsTmp)
        this.result = Object.freeze(res)
        this.showLoading = false
        this.batch_sn = res.data.batch_no
        if (res.code !== 200) return this.isVisible = true, this.downList = res.data
        if (res.code === 200) {
          if (res?.data?.err_order?.length) {
            this.checkCreateErrstatusDialog.display()
            this.downList = getDownList(res?.data?.err_order)
            this.partialSuccessData = { err_order: res?.data?.err_order?.map(item => item?.order_list?.[0]?.sku_list?.[0] ?? {}) }
            return
          }
        }
        this.$message.success('导入成功')
        this.active = 2
      } catch (error) {
        this.showLoading = false
      }
    },
    confirmPay () {
      this.checkCreateErrstatusDialog.dismiss()
      if (!this.result?.data?.batch_no || this.result?.data?.normal_order?.length === 0) return
      this.active = 2
    },
    handleResults (results) {
      if (!Array.isArray(results)) return
      const tHeaders = { "商品编号": 'goods_sn', "数量": 'num', '收货人姓名': 'addr_name', '手机号': 'mobile', '详细地址': 'addr', '订单备注': 'order_remark' }
      return results.map(item => Object.fromEntries(Object.entries(item).map((item) => {
        item[0] = tHeaders[item[0]]
        return item
      })))
    },
    downDataTemplate () {
      const tHeaders = ['goods_sn', 'num', 'addr_name', 'mobile', 'addr', 'order_remark', 'error_msg']
      downloadErr(["商品编号", "数量", "收货人姓名", "手机号", '详细地址', '订单备注', '失败原因'], this.downList.map(item => tHeaders.map(head => item[head])), [],
        getDate())
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #fff;
  min-height: 90vh;

  .step1 {
    ::v-deep .el-step__head {
      border-color: #303133;
    }

    ::v-deep .el-step__title {
      color: #303133;
      font-weight: bold;
    }
  }

  .five {
    ::v-deep .el-icon-check::before {
      content: '\e79c' !important;
      font-size: 26px;
      /* 注意，这里需更换为所需成功状态显示icon编码 */
    }
  }

  /* 注意，以下代码需替换为自己生成icon样式！！！ */
  .tip ::v-deep.el-step__icon-inner.is-status.el-icon-check {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 20px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

.toolbar-title {
  height: 25.5px;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  padding-left: 4px;
  margin-right: 12px;

  &::before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #1A43A9;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate(0, -50%);
  }
}
</style>